import * as React from "react"
import { Container } from "react-bootstrap"
import Layout from "../components/layout";


const JakToDzialaPage = () => {
  return (
    <Layout>
      <Container className="p-5">
        <h1 class="text-center">
          Dzierżawa gruntu pod farmy fotowoltaiczne
        </h1>
        <h2 class="text-center">
          - dlaczego teraz jest najbardziej właściwy czas na taką inwestycję
        </h2>
      </Container>
      <Container className="p-3 lh-lg">
        <h4>SKOK TECHNOLOGICZNY W BRANŻY FOTOWOLTAICZNEJ</h4>
        <p className="px-2">
        Na przestrzeni ostatnich lat można zaobserwować niesamowity skok
        technologiczny w branży fotowoltaicznej. Obserwujemy taniejące z roku na
        rok komponenty, coraz większą wydajność i trwałość paneli, sprzyjające
        otoczenie prawne. To wszystko powoduje, że teraz bardziej, niż
        kiedykolwiek opłaca się budowanie farm fotowoltaicznych. Trend opanował
        całą Europę, w tym Polskę, gdzie w krajobraz polskich domów
        jednorodzinnych na trwałe wpisały się umieszczone na dachach panele
        fotowoltaiczne. Fundusze inwestycyjne, Spółki notowane na warszawskiej
        Giełdzie Papierów Wartościowych, a także indywidualni inwestorzy
        dostrzegają ten potencjał, co spowodowało duży popyt na grunty nadające
        się do prowadzenia działalności w skali nieco większej niż przydomowa
        instalacja.
        </p>
      </Container>
      <Container className="p-3 lh-lg">
        <h4>ARGUMENT FINANSOWY</h4>
        <p className="px-2">
        Należy sobie w związku z tym odpowiedzieć na pytanie – dlaczego warto
        dzierżawić grunty? Najważniejszy i najbliższy koszuli jest przede
        wszystkim wątek finansowy. Dane GUS z dnia 23 września 2020 r. wskazują,
        że przeciętny dochód z pracy w indywidualnych gospodarstwach rolnych z 1
        ha przeliczeniowego wyniósł w 2019 r. 3.244 zł rocznie. Wyliczenia
        wojewódzkich ODR wskazują, że rentowność upraw hektara ziemniaków na
        glebach klasy III i IV wahają się od 6.000-7.000PLN, wliczając dopłaty,
        a nie wliczając czasu, który rolnik musi zainwestować w przygotowanie
        gleby, nawożenie, zbiory, sprzedaż. Dzierżawa gruntów pod farmę
        fotowoltaiczną może przynieść od 5.000- 12.000PLN rocznie za hektar, w
        zależności od tego jak bardzo działka spełnia wymagania
        (nasłonecznienie, kompleksowość formalności potrzebnych do
        przeprowadzenia inwestycji, ekspozycja). Co to oznacza dla właściciela
        gruntu? W najgorszym przypadku efektywność finansowa jest podobna,
        natomiast w najlepszym premia może przekroczyć 100% - bez jakiegokolwiek
        nakładu pracy.
        </p>
      </Container>
      <Container className="p-3 lh-lg">
        <h4>KORZYSTNE OTOCZENIE MAKROEKONOMICZNE</h4>
        <p className="px-2">
        Korzystnie wygląda również otoczenie makroekonomiczne. Unia Europejska
        zdecydowała, że do 2030 roku udział zielonej energii w miksie konsumpcji
        energii elektrycznej, cieplnej i w transporcie powinien wzrosnąć do roku
        2030 przynajmniej do 32 proc. – w stosunku do celu na poziomie 20 proc.
        wyznaczonego przez UE na rok 2020. Polska nie zdołała spełnić celu na
        rok 2020. Mamy w związku z tym silne przesłanki ku temu, że otoczenie
        prawne wokół fotowoltaiki się nie zmieni, a może być nawet bardziej
        korzystne. W przeciwnym razie, Polska, nie spełni założonych przez Unię
        wymagań.
        </p>
        <p className="px-2"> 
        Trzeba również zwrócić uwagę, że trudna sytuacja gospodarcza kraju,
        spowodowana pandemią powoduje, że każdy grosz wydawany przez Państwo
        jest szczegółowo oglądany (przynajmniej w teorii). Inwestycje w
        energetykę są bardzo kosztowne. Mając to na uwadze Państwo będzie
        wykorzystywało mechanizmy partnerstwa publiczno-prywatnego żeby
        realizować inwestycję. Już działający system aukcji organizowany przez
        Urząd Regulacji Energetyki zapewne zostanie zachowany, a może zostaną
        wprowadzone nowe narzędzia państwowej regulacji, tak by zwiększyć
        inicjatywę osób prywatnych i przedsiębiorstw działających w branży.
        Wspomniane regulacje zapewniają odbiór wytworzonej energii i stałej ceny
        obowiązującą nawet na 15 lat do przodu. To również oznacza stabilizację
        branży i zwiększa chęć inwestycji, gdyż łatwo ocenić jej marżowość w
        długiej perspektywie.
        </p>
      </Container>
      <Container className="p-3 lh-lg">
        <h4>ROSNĄCE CENY PRĄDU</h4>
        <p className="px-2">
        To, co boli w ostatnich latach, to rosnące ceny prądu. Większość
        analityków wskazuje na pogłębienie tego trendu i dalsze wzrosty w
        nadchodzących latach. Z perspektywy konsumenta stanowi to problem  i
        zmniejsza ilość złotówek w portfelu. Natomiast z perspektywy inwestora i
        dzierżawcy należy w tym upatrywać szansy. Jednym z mechanizmów dzierżawy
        jest stała stawka waloryzowana tylko o stopę inflacji, ale nic nie stoi
        na przeszkodzie, żeby dzierżawca partycypował w zyskach funduszu
        inwestycyjnego, który zrealizuje budowę farmy fotowoltaicznej i zyskiwał
        dodatkowe korzyści wraz ze wzrostem cen prądu, lub poprawą wydajności
        instalacji.
        </p>
      </Container>
      <Container className="p-3 lh-lg">
        <h4>CHRONIMY NASZE ŚRODOWISKO</h4>
        <p className="px-2">
        Kolejną przesłanką jest ochrona środowiska, chcemy się kąpać w czystych
        rzekach, oddychać świeżym powietrzem, bez smogu, jeść żywność z gleb
        wolnych od zanieczyszczeń produkcyjnych. Ludzie często zadają sobie
        pytanie, co sam mogę zrobić żeby poprawić jakość powietrza. Nie ulega
        wątpliwości, że inwestycję w fotowoltaikę kosztem energii węglowej to
        krok w dobrą stronę. To krok w stronę zimy bez smogu.
        </p>
        <p className="px-2">
        Reasumując warto bo:
        <ul>
          <li>
            dochody przewyższają te z uprawy roli, bez nakładu pracy rolnika,
          </li>
          <li>
            rynek będzie poszukiwał taniej, czystej energii elektrycznej, i
            należy oczekiwać dalszego wzrostu cen energii,
          </li>
          <li>
            dbamy o środowisko naturalne – i zapewniamy czyste powietrze
            kolejnym pokoleniom.
          </li>
        </ul>
        </p>
      </Container>
      <Container className="p-3 lh-lg">
        <h4>WSZYSTKO ZROBIMY ZA CIEBIE</h4>
        <p className="px-2">
        Zdecydowanym minusem jest długotrwały i skomplikowany proces
        przygotowania wszystkich niezbędnych dokumentów, które umożliwią
        postawienie farmy fotowoltaicznej. Prowadzone postępowania w urzędach
        niejednokrotnie są czasochłonne. Do tego dochodzą wysokie koszty
        skompletowania niezbędnej dokumentacji i projektu. Bez gwarancji
        finalizacji projektu.
        </p>
        <p className="px-2">
        Naszym celem jest przejęcie na siebie tej części procesu inwestycyjnego:
        przejmiemy przygotowanie formalności, prowadzenie spraw urzędowych,
        przygotowanie projektu łącznie z uzyskanie pozwolenia na budowę farmy.
        Wszelkie koszty, z tym związane weźmiemy na siebie. Sami zrealizujemy
        projekt, lub znajdziemy fundusz inwestycyjny skłonny zainwestować w
        farmę na odpowiednich gruntach.
        </p>
      </Container>
      <Container className="p-3 lh-lg">
        <h4>JAKICH DZIAŁEK SZUKAMY</h4>
        <p className="px-2">
        Jeżeli dysponujesz działką o następujących charakterystykach:
        <ul>
          <li>powierzchnia 1,5 ha lub większa</li>
          <li>IV lub niższej klasie gruntu</li>
          <li>
            znajdująca się w odległości do 500 metrów od sieci średniego
            napięcia
          </li>
        </ul>
        </p>
        <p className="px-2">
        Zachęcamy do współpracy – podpiszemy umowę dzierżawy na korzystnych
        warunkach i przeprowadzimy profesjonalnie proces inwestycyjny, w taki
        sposób żeby zapewnić źródło ciągłego, pewnego dochodu z działki przez
        kolejnych 30 lat.
        </p>
      </Container>
    </Layout>
  )
}

export default JakToDzialaPage
